export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export const mapErrors = error => {
  let response = '';
  switch (error) {
    case 'Network Error': response = 'Falha na comunicação com o Servidor.'; break;
    case 'Request failed with status code 400': response = 'Falha na requisição. Tente novamente.'; break;
    case 'Request failed with status code 403': response = 'Acesso negado.'; break;
    default: response = error;
  }
  return response;
}