export default {
  set(key, value) {
    const parsedValue = JSON.stringify(value);
    sessionStorage.setItem(key, parsedValue);
  },
  get(key) {
    return JSON.parse(
      sessionStorage.getItem(key)
    );
  },
  del(key) {
    sessionStorage.removeItem(key);
  },
  clear() {
    sessionStorage.clear();
  }
};
