import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './actions';
import session from '../../shared/session';

// import * as modules from '../../modules/*/store';

Vue.use(Vuex);

const state = {
  loading: false,
  loadingCount: 0,
  loja: null,
  schema: session.get('schema') || null,
  token: session.get('token') || null,
  colors: ['#ffc107', '#63c2de', '#20c997', '#f86c6b', '#5fffd0', '#ec9472', '#95d18d', '#ebc173', '#5fc4ff', '#c17fdf'],
  profile: (session.get('profile')) || {}
};

const strict = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  strict,
  state,
  mutations,
  actions/* ,
  modules */
});
