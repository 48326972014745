import Vue from 'vue'
import Router from 'vue-router'
import session from '../shared/session'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const DefaultContainerListaVez = () => import('@/containers/DefaultContainerListaVez')

// Views
const Login = () => import('@/views/Login')
const Dashboard = () => import('@/views/Dashboard')
const Vendas = () => import('@/views/Vendas')
const Consignacoes = () => import('@/views/Consignacoes')
const Metas = () => import('@/views/Metas')
const FluxoAtendimentos = () => import('@/views/FluxoAtendimentos')
const ProdutosMaisVendidos = () => import('@/views/ProdutosMaisVendidos')
const ProdutosMenosVendidos = () => import('@/views/ProdutosMenosVendidos')
const MixProdutosVendidos = () => import('@/views/MixProdutosVendidos')
const ClientesAtendidos = () => import('@/views/ClientesAtendidos')
const VendasPerdidas = () => import('@/views/VendasPerdidas')
const FilaAtendimento = () => import('@/views/lista-vez/FilaAtendimento')
const Performance = () => import('@/views/lista-vez/Performance')
const Nps = () => import('@/views/Nps')
const PesquisaNps = () => import('@/views/PesquisaNps')

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '*', redirect: '/' },
    {
      path: '/',
      redirect: '/login',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/login',
          component: Login
        }
      ]
    },
    {
      path: '/home',
      redirect: '/dashboard',
      component: DefaultContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: { requiresAuth: true }
        },
        {
          path: '/vendas',
          name: 'Vendas',
          component: Vendas,
          meta: { requiresAuth: true }
        },
        {
          path: '/consignacoes',
          name: 'Consignações',
          component: Consignacoes,
          meta: { requiresAuth: true }
        },
        {
          path: '/metas',
          name: 'Metas',
          component: Metas,
          meta: { requiresAuth: true }
        },
        {
          path: '/fluxo-atendimentos',
          name: 'Fluxo de Atendimentos',
          component: FluxoAtendimentos,
          meta: { requiresAuth: true }
        },
        {
          path: '/produtos-mais-vendidos',
          name: 'Produtos Mais Vendidos',
          component: ProdutosMaisVendidos,
          meta: { requiresAuth: true }
        },
        {
          path: '/produtos-menos-vendidos',
          name: 'Produtos Menos Vendidos',
          component: ProdutosMenosVendidos,
          meta: { requiresAuth: true }
        },
        {
          path: '/mix-produtos-vendidos',
          name: 'Mix de Produtos Vendidos',
          component: MixProdutosVendidos,
          meta: { requiresAuth: true }
        },
        {
          path: '/clientes-atendidos',
          name: 'Clientes Atendidos',
          component: ClientesAtendidos,
          meta: { requiresAuth: true }
        },
        {
          path: '/vendas-perdidas',
          name: 'Vendas Perdidas',
          component: VendasPerdidas,
          meta: { requiresAuth: true }
        },
        {
          path: '/nps',
          name: 'NPS',
          component: Nps,
          meta: {
            requiresAuth: true,
            disabled: ( process.env.NODE_ENV === 'production' ? [62, 173, 509, 577, 306, 108, 296, 526, 494, 148, 540] : [93, 384] ).includes(session.get('grupo')) ? false : true
          }
        }
      ]
    },
    {
      path: '/lista-vez',
      redirect: '/fila',
      component: DefaultContainerListaVez,
      children: [
        {
          path: '/fila',
          name: 'Fila de Atendimento',
          component: FilaAtendimento,
          meta: { requiresAuth: true }
        },
        {
          path: '/performance/:vendedor_id/:loja/:foto/:vendedor_nome',
          name: 'Performance',
          component: Performance,
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: '/pesquisa-nps/:param',
      props: true,
      component: PesquisaNps
    }
  ]
})
