import './polyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import JsonExcel from 'vue-json-excel'
import App from './App'
import router from './router/index'
import axios from 'axios'
import store from './core/store'
import swal from 'sweetalert'
import {mapErrors} from './shared/utils'
import session from './shared/session'
import VueCurrencyFilter from 'vue-currency-filter'
import VueMq from 'vue-mq'

const {dispatch, state} = store;

axios.interceptors.request.use(config => {
  dispatch('START_LOADING');
  if (state.token) {
    config.headers['X-Access-Token'] = state.token;
  }
  const {method} = config;
  if (method === 'get' && state.schema) {
    config.params['schema'] = state.schema
    config.params['vendedor'] = state.profile.nivel === 'Vendedor' ? state.profile.codigo : null
  } else if (method === 'post' && state.schema) {
    config.data['schema'] = state.schema
    config.data['vendedor'] = state.profile.nivel === 'Vendedor' ? state.profile.codigo : null
  }
  return config;
}, error => {
  dispatch('STOP_LOADING');
  swal({
    title: 'Erro',
    text: mapErrors(error.message),
    icon: 'error'
  });
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  dispatch('STOP_LOADING');
  return response;
}, error => {
  dispatch('STOP_LOADING');
  if (error.response.data.message === 'jwt expired') {
    dispatch('LOGOUT');
    router.push('/login');
    return;
  }
  swal({
    title: 'Erro',
    text: mapErrors(error.message),
    icon: 'error'
  });
  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = (
      session.get('token') &&
      session.get('schema') &&
      session.get('grupo') &&
      session.get('modo')
    );

    if (authRequired && !loggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.component('downloadExcel', JsonExcel)
Vue.use(VueMq)
Vue.use(BootstrapVue)
Vue.use(VueCurrencyFilter,
{
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
});
Vue.filter('number', value => {
  return Number(value || 0).toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})
});

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  },
  watch: {
    '$route': function(to, from) {
      if (to.meta.disabled) {
        this.$router.push({ path: from.path })
        return swal({
          title: 'Aviso',
          text: 'Em Desenvolvimento',
          icon: 'info'
        });
      }
    }
  },
  created() {
    const isTesteEnv = window.location.host.includes('teste')
    document.title = isTesteEnv ? `${document.title} (Teste)` : document.title
  }
})
