import session from '../../shared/session'

export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';

export const LOADING_INCREMENT = 'LOADING_INCREMENT';
export const LOADING_DECREMENT = 'LOADING_DECREMENT';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_TOTAL_CLIENTES = 'SET_TOTAL_CLIENTES';
export const CLASSES = 'CLASSES';
export const CLASSIFICACOES = 'CLASSIFICACOES';
export const GRUPOS = 'GRUPOS';
export const SUBGRUPOS = 'SUBGRUPOS';
export const FORNECEDORES = 'FORNECEDORES';
export const VENDEDORES = 'VENDEDORES';
export const LOJAS = 'LOJAS';
export const FORMA_VENDAS = 'FORMA_VENDAS';
export const GESTOES = 'GESTOES';
export const PROFILE = 'PROFILE';

export default {
  [LOADING_START](state) {
    state.loading = true;
  },
  [LOADING_STOP](state) {
    state.loading = false;
  },
  [LOADING_INCREMENT](state) {
    state.loadingCount++;
  },
  [LOADING_DECREMENT](state) {
    state.loadingCount--;
  },
  [LOGIN](state, {
    modo,
    grupo,
    token,
    adm_estabel_centres_id,
    nome_estabel_centres
  }) {
    session.set('modo', modo);
    session.set('grupo', grupo);
    session.set('loja', adm_estabel_centres_id);
    session.set('loja_desc', nome_estabel_centres);
    session.set('schema', `ge_${grupo}`);
    session.set('token', token);
    state.schema = `ge_${grupo}`;
    state.grupo = grupo;
    state.token = token;
    state.loja = adm_estabel_centres_id;
  },
  [LOGOUT](state) {
    state.token = null;
    state.schema = null;
    session.clear();
  },
  [SET_TOTAL_CLIENTES](state, totalClientes) {
    session.set('total-clientes', totalClientes);
  },
  [CLASSES](state, classes) {
    session.set('classes', classes);
  },
  [CLASSIFICACOES](state, classificacoes) {
    session.set('classificacoes', classificacoes);
  },
  [GRUPOS](state, grupos) {
    // grupos.unshift({ value: 0, text: 'Todos os Grupos', selectAll: true});
    session.set('grupos', grupos);
  },
  [SUBGRUPOS](state, subgrupos) {
    // subgrupos.unshift({ value: 0, text: 'Todos os Subgrupos', selectAll: true});
    session.set('subgrupos', subgrupos);
  },
  [FORNECEDORES](state, fornecedores) {
    // fornecedores.unshift({ value: 0, text: 'Todos os Fornecedores', selectAll: true});
    session.set('fornecedores', fornecedores);
  },
  [VENDEDORES](state, vendedores) {
    // vendedores.unshift({ value: 0, text: 'Todos os Vendedores', selectAll: true});
    session.set('vendedores', vendedores);
  },
  [LOJAS](state, lojas) {
    lojas.unshift({ value: 0, text: 'Todas as Lojas' });
    session.set('lojas', lojas);
  },
  [FORMA_VENDAS](state, formas) {
    formas.unshift({ value: 0, text: 'Todas as Formas de Venda' });
    session.set('formas', formas);
  },
  [GESTOES](state, gestoes) {
    gestoes.unshift({ value: 0, text: 'Todas as Gestões' });
    session.set('gestoes', gestoes);
  },
  [PROFILE](state, profile) {
    state.profile = profile
    session.set('profile', profile);
  }
};
