import {debounce, throttle} from 'lodash';

import {
  LOADING_START,
  LOADING_STOP,
  LOADING_INCREMENT,
  LOADING_DECREMENT,
  LOGIN,
  LOGOUT,
  SET_TOTAL_CLIENTES,
  CLASSES,
  CLASSIFICACOES,
  GRUPOS,
  SUBGRUPOS,
  FORNECEDORES,
  VENDEDORES,
  LOJAS,
  FORMA_VENDAS,
  GESTOES,
  PROFILE
} from './mutations';

const wait = 200;

const hideLoader = debounce(commit => {
  commit(LOADING_STOP);
}, wait);

const showLoader = throttle(commit => {
  commit(LOADING_START);
}, wait);

export default {
  START_LOADING({commit, state}) {
    if (state.loadingCount === 0) {
      hideLoader.cancel();
      showLoader(commit);
    }

    commit(LOADING_INCREMENT);
  },
  STOP_LOADING({commit, state}) {
    commit(LOADING_DECREMENT);

    if (state.loadingCount === 0) {
      hideLoader(commit);
    }
  },
  LOGIN({commit, state}, {
      modo,
      grupo,
      token,
      adm_estabel_centres_id,
      nome_estabel_centres
    }) {
    commit(LOGIN, {
      modo,
      grupo,
      token,
      adm_estabel_centres_id,
      nome_estabel_centres
    });
  },
  LOGOUT({commit, state}) {
    commit(LOGOUT);
  },
  SET_TOTAL_CLIENTES({commit, state}, totalClientesGrupo) {
    commit(SET_TOTAL_CLIENTES, totalClientesGrupo)
  },
  CLASSES({commit, state}, classes) {
    commit(CLASSES, classes)
  },
  CLASSIFICACOES({commit, state}, classificacoes) {
    commit(CLASSIFICACOES, classificacoes)
  },
  GRUPOS({commit, state}, grupos) {
    commit(GRUPOS, grupos)
  },
  SUBGRUPOS({commit, state}, subgrupos) {
    commit(SUBGRUPOS, subgrupos)
  },
  FORNECEDORES({commit, state}, fornecedores) {
    commit(FORNECEDORES, fornecedores)
  },
  VENDEDORES({commit, state}, vendedores) {
    commit(VENDEDORES, vendedores)
  },
  LOJAS({commit, state}, lojas) {
    commit(LOJAS, lojas)
  },
  FORMA_VENDAS({commit, state}, formas) {
    commit(FORMA_VENDAS, formas)
  },
  GESTOES({commit, state}, gestoes) {
    commit(GESTOES, gestoes)
  },
  PROFILE({commit, state}, profile) {
    commit(PROFILE, profile)
  },
  async FILTRAR_LOJAS_POR_GESTAO_MULTI({commit},ArrayGestoesLojas){
    const gestoes = ArrayGestoesLojas[0];
    const lojas = ArrayGestoesLojas[1];

    if(!gestoes || gestoes.length === 0){
      return lojas;
    }

    let lojasFiltradasPorGestao = [];

    gestoes.forEach(gestao => {
      const lojasPorGestao = lojas.filter( loja => loja.gestao === gestao.value);
      if(lojasPorGestao){
        lojasFiltradasPorGestao.push(...lojasPorGestao);
      }
    });

    return lojasFiltradasPorGestao;
  },
  async FILTRAR_LOJAS_POR_GESTAO_UNI({commit},ArrayGestoesLojas){
    const gestao = ArrayGestoesLojas[0];
    const lojas = ArrayGestoesLojas[1];

    if(!gestao){
      return lojas;
    }

    let lojasFiltradasPorGestao = lojas.filter(loja => loja.gestao === gestao);
    lojasFiltradasPorGestao.unshift({text: 'Todas as Lojas', value: 0})

    return lojasFiltradasPorGestao;
  }
};
